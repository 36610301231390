.header {
	flex: 1 0 100%;
	position: relative;
	background: url("../images/bg.jpg") center/cover;

	&::after {
		@include absolute($right: 0, $bottom: 0, $left: 0);
		content: '';
		background: linear-gradient(180deg, rgba(58, 137, 241, 0) 0%, rgba(14, 39, 149, 0.35) 100%);
		height: 100px;
		pointer-events: none;
	}

	&__image {
		position: relative;
		display: inline-block;
		width: 50%;
		height: 100%;
		background: url("../images/mobile.png") center bottom/ min(555px, 100%) auto no-repeat;

		img {
			@include absolute($bottom: 0, $left: 10%);
			width: 100%;
			max-width: 555px;
			transform: translateX(-50%);
		}
	}

	&__content {
		@include absolute($top: 0, $left: 50%);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 100%;
	}

	&__logo {
		max-width: 100%;
		margin-bottom: 18px;
	}

	&__slogan {
		font-size: 32px;
		color: $color-white;
		letter-spacing: 0.02em;
		margin-bottom: 48px;
	}

	&__heart {
		@include absolute($top: 36px, $left: 36px);
		filter: drop-shadow(2px 10px 30px rgba(186, 0, 0, 0.3));
	}

	&__banner {
		@include absolute($top: 0, $left: 0);
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		width: 100%;
	}
}

@include table-screen() {
	.header {
		&__logo {
			max-width: 280px;
			margin-bottom: 32px;
		}

		&__slogan {
			font-size: 24px;
			margin-bottom: 48px;
		}
	}
}

.mobile {
	.header {
		background: url("../images/bg_m.jpg") top/cover;

		&::after {
			content: none;
		}

		&__content {
			left: 0;
			justify-content: flex-end;
			width: 100%;
			padding: 0 28px 114px;
		}
	}
}

.store-banner {
	display: flex;
	align-items: center;
	background-color: $color-white;
	text-align: left;
	padding: 0 12px 0 8px;
	height: 80px;
	overflow: hidden;

	&__logo {
		flex-shrink: 0;
		font-size: 0;
		margin-right: 8px;
	}

	&__content {
		flex: 1 1 auto;
		font-size: 11px;
		line-height: 1.18;
		margin-right: 4px;
	}

	&__title {
		font-weight: 500;
		font-size: inherit;
		margin-bottom: 4px;
	}

	&__desc {
		color: $color-text-gray1;
		margin-bottom: 4px;
	}
}
