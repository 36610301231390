.button {
	display: flex;
	align-items: center;
	justify-content: center;
}

.button_size-s {
	border-radius: 18px;
	font-size: 12px;
	font-weight: bold;
	height: 36px;
	padding: 0 16px;
}

.button_size-l {
	border-radius: 34px;
	font-size: 24px;
	font-weight: 500;
	height: 68px;
	padding: 0 18px;
}

.button_color-b {
	background-color: $color-purplish-blue;
	color: $color-white;
}

.button_color-o {
	background-color: $color-orange;
	color: $color-white;
}

.button_shadow.button_color-o.button_size-l {
	box-shadow: 0 5px 0 #CD3D07, 0 5px 14px rgba(228, 83, 29, 0.2);
}

.button_w100 {
	width: 100%;
}
