.store-badges {
	display: flex;
	justify-content: center;

	&__item {
		display: flex;
		align-items: center;

		a {
			display: inline-block;
			font-size: 0;
			margin-right: 18px;
		}
	}
}
