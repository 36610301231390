//@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;600;700&display=swap');

@font-face {
	font-family: 'Lexend';
	src: url("../fonts/Lexend-Light.ttf");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Lexend';
	src: url("../fonts/Lexend-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Lexend';
	src: url("../fonts/Lexend-SemiBold.ttf");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Lexend';
	src: url("../fonts/Lexend-Bold.ttf");
	font-weight: bold;
	font-style: normal;
}
