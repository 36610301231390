.main-content {
	flex-shrink: 0;
	position: relative;
	background: url("../images/spots_2.png") center 90% / clamp(620px, 100%, 1255px) auto no-repeat, linear-gradient(0deg, rgba(45, 0, 172, 0.2), rgba(136, 36, 233, 0) 684px), $color-blue-light;
	margin-top: -100vh;

	h2, h4 {
		color: $color-white;
	}

	h2 {
		font-size: 48px;
		line-height: 1.14;
	}

	h3 {
		font-size: 28px;
	}

	h4 {
		font-size: 20px;
	}
}

.section-intro {
	padding: 204px 0 243px;

	img {
		margin-bottom: -8px;
	}

	h2 {
		margin: 0 -80px 40px;

		.strike {
			color: #aed8ff;

			&::after {
				color: $color-blue-dark;
			}
		}
	}

	p {
		font-size: 24px;
		font-weight: 300;
		color: $color-white;
	}
}

@media (max-width: 1180px) {
	.section-intro {
		h2 {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.strike::after {
			left: 0;
			top: 100%;
			transform: translate(-40%, -20%) rotate(-3deg);
		}
	}
}

.section-wrapper {
	position: relative;
	background: url("../images/spots.png") center 42% / clamp(620px, 100%, 1582px) auto no-repeat,
	linear-gradient(180deg, #190077 0%, #2D00AC 50%, rgba(136, 36, 233, 0) 100%) top/100% 50% no-repeat,
	radial-gradient(111.67% 171.78% at -14.03% -32.17%, #00D1FF 0%, #0090FF 45.08%, #0118E4 100%);
	padding: 136px 0 336px;
}

.section-profile {
	position: relative;
	margin-bottom: 188px;
	z-index: 1;

	h2 {
		margin-bottom: 64px;
	}

	p {
		font-size: 24px;
		font-weight: 300;
		color: $color-blue-light;
		margin-bottom: 80px;
	}

	&__image {
		grid-column: 2/span 10;
		line-height: 0;
	}
}

.section-match {
	position: relative;
	z-index: 1;

	h2 {
		color: $color-white;
		margin-bottom: 44px;
		line-height: 1.83;
		white-space: nowrap;

		mark {
			position: relative;
			display: inline-block;
			background: none;
			color: inherit;
			padding: 0 12px;

			&::before {
				content: '';
				@include absolute($top: 0, $right: -4px, $bottom: 0, $left: -4px);
				background-color: $color-orange;
				border-radius: 8px;
				box-shadow: -8px 8px 20px rgba(4, 0, 183, 0.5);
				z-index: -1;
			}
		}
	}

	p {
		font-size: 24px;
		font-weight: 300;
		color: $color-white;
	}

	p + p {
		margin-top: 1.6em;
	}

	&__image {
		grid-column: 1 / span 6;
		padding: 0 8%;
		line-height: 0;
	}

	&__content {
		grid-column: 7 / span 5;
		text-align: left;
		padding-top: 50px;
	}
}

.section-features {
	position: relative;
	background-color: $color-white;

	&::before {
		@include absolute($top: 1px, $right: 0, $left: 0);
		content: '';
		background: url("../images/wave_white.svg") center/100% 100% no-repeat;
		transform: translateY(-100%);
		height: calc(100vw / 3.2);
		max-height: 476px;
	}

	.container {
		row-gap: 18px;
		transform: translateY(-206px);
	}
}

.feature {
	grid-column: 2 / span 5;
	text-align: left;
	padding: 0 48px;

	&__icon {
		width: 154px;
		height: 154px;
		margin: 0 0 0 -30px;
	}

	&__title {
		margin-bottom: 20px;
	}

	&__desc {
		font-size: 20px;
		font-weight: 300;
		color: $color-black2;
	}

	&:nth-child(even) {
		grid-column-start: 7;
	}
}

.section-stories {
	position: relative;
	z-index: 1;
	margin-top: -64px;
	padding-top: 186px;

	&::before {
		@include absolute($top: 0, $right: 0, $left: 0);
		content: '';
		background: url("../images/wave_blue.svg") center/100% 100% no-repeat, $color-white;
		height: 298px;
		z-index: -1;
	}

	h2 {
		margin-bottom: 40px;
	}

	p {
		font-size: 20px;
		font-weight: 300;
		line-height: 1.6;
		margin-bottom: 60px;
	}

	a {
		color: $color-secondary-yellow;
		font-weight: bold;

		&::after {
			content: '';
			display: inline-block;
			border: 3px solid currentColor;
			border-top: none;
			border-left: none;
			width: 10px;
			height: 10px;
			transform: rotate(-45deg);
			margin-left: 8px;
		}
	}

	&__list {
		grid-column: 2 / span 10;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		margin-top: 60px;
	}
}

.review {
	display: flex;
	align-items: center;
	background-color: $color-white;
	border: 1px solid $color-gray1;
	border-radius: 40px;
	padding: 20px 20px 18px;

	&__photo {
		border-radius: 22%;
		width: 35%;
		height: auto;
		margin-right: 30px;
	}

	&__content {
		text-align: left;
	}

	&__text {
		font-size: 18px;
		color: $color-black2;
		line-height: 1.55;
		margin-bottom: 8px;
	}

	&__info {
		font-weight: 300;
		color: $color-gray4;
	}

	&:nth-child(odd) {
		border-bottom-left-radius: 0;
	}

	&:nth-child(even) {
		border-bottom-right-radius: 0;
	}
}

.section-promo {
	padding: 242px 20px 306px;

	&__ico {
		margin-bottom: 4px;
	}

	h2 {
		margin-bottom: 36px;
	}

	p {
		font-size: 24px;
		color: $color-white;
		margin-bottom: 46px;
	}

	.store-badges {
		&__item {
			flex-direction: column;

			a {
				margin: 0 0 24px 0;
			}
		}
	}
}

@include table-screen() {
	.main-content {
		background-position-y: 94%, 0, 0;

		h2 {
			font-size: 36px;
		}

		h3 {
			font-size: 26px;
		}

		h4 {
			font-size: 18px;
		}
	}

	.section-intro {
		img {
			width: 128px;
			height: 110px;
			margin-bottom: 0;
		}

		h2 {
			margin-bottom: 28px;
		}

		p {
			font-size: 20px;
		}
	}

	.section-wrapper {
		padding: 108px 0 0;
		background-position-y: 32%, top, 0;
	}

	.section-profile {
		margin-bottom: 60px;

		h2 {
			margin-bottom: 48px;
		}

		p {
			font-size: 20px;
			margin-bottom: 16px;
		}
	}

	.section-match {
		h2 {
			margin-bottom: 28px;
		}

		p {
			font-size: 20px;
		}

		&__image {
			padding: 0;
		}

		&__content {
			order: -1;
			text-align: center;
			padding-top: 0;
			margin-bottom: 36px;
		}
	}

	.section-features {
		row-gap: 40px;
		padding: 64px 0 84px;

		&::before {
			background-image: url("../images/wave_white_m.svg");
			height: 186px;
		}

		.container {
			transform: none;
		}
	}

	.feature {
		text-align: center;
		padding: 0 16px;

		&__icon {
			width: 141px;
			height: 141px;
			margin: 0;
		}

		&__title {
			margin-bottom: 20px;
		}

		&__desc {
			font-size: 18px;
		}

		&:nth-child(1) {
			order: 1;
		}

		&:nth-child(2) {
			order: 3;
		}

		&:nth-child(3) {
			order: 2;
		}

		&:nth-child(4) {
			order: 4;
		}
	}

	.section-stories {
		margin-top: 0;
		padding-top: 166px;

		&::before {
			background-image: url("../images/wave_blue_m.svg"), initial;
			height: 92px;
		}

		h2 {
			margin-bottom: 40px;
		}

		p {
			font-size: 18px;
			margin-bottom: 40px;
		}

		a {
			display: inline-block;
			margin-top: 36px;

			&::after {
				border-width: 3px;
				width: 8px;
				height: 8px;
				margin-left: 4px;

			}
		}

		&__list {
			display: flex;
			flex-direction: column;
			row-gap: 60px;
			margin-top: 108px;
		}
	}

	.review {
		flex-direction: column;
		border-radius: 16px !important;
		padding: 0 24px 20px;

		&__photo {
			border-radius: 20px;
			width: 88px;
			height: 88px;
			margin: -32px 0 12px 0;
		}

		&__content {
			text-align: center;
		}

		&__text {
			font-size: 16px;
		}
	}

	.section-promo {
		padding: 146px 20px;

		&__ico {
			width: 125px;
			height: 125px;
			margin-bottom: 20px;
		}

		h2 {
			margin-bottom: 20px;
		}

		p {
			font-size: 20px;
			margin-bottom: 40px;
		}
	}
}

@include mobile-screen() {
	.main-content {
		h2, h3 {
			font-size: 24px;
		}

		h4 {
			font-size: 16px;
		}
	}

	.section-intro {
		padding: 80px 0;

		img {
			width: 98px;
			height: 84px;
			margin-bottom: 10px;
		}

		h2 {
			margin: 0 0 20px;
		}

		p {
			font-size: 16px;
		}
	}

	.section-wrapper {
		padding: 80px 0 0;
	}

	.section-profile {
		margin-bottom: 40px;

		p {
			font-size: 16px;
		}

		&__image {
			margin: 0 -20px;
		}
	}

	.section-match {
		h2 {
			mark {
				padding: 0 10px;
				margin-left: 4px;
			}
		}

		p {
			font-size: 16px;
		}

		&__image {
			margin: 0 -20px;
		}
	}

	.feature {
		&__icon {
			width: 128px;
			height: 128px;
		}

		&__desc {
			font-size: 16px;
		}
	}

	.section-stories {
		p {
			font-size: 16px;
		}

		&__list {
			margin-right: -8px;
			margin-left: -8px;
		}
	}

	.review {
		&__content {
			text-align: center;
		}

		&__text, &__info {
			font-size: 14px;
		}
	}

	.section-promo {
		&__ico {
			width: 90px;
			height: 90px;
		}

		p {
			font-size: 16px;
		}
	}
}


