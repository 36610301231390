$font-primary: 'Lexend', sans-serif;

$color-black: #000;
$color-black2: #212121;
$color-white: #fff;
$color-blue: #018eff;
$color-blue-light: #46a6ff;
$color-blue-dark: #0400b7;
$color-purplish-blue: #3a36db;
$color-orange: #ff6125;
$color-secondary-yellow: #ffb27a;
$color-text-gray1: #888;

$color-purple-light: #9f5a89;
$color-purple: #5e2c4e;
$color-red-light: #ff766a;
$color-orange-light: #f8d1c5;

$color-primary-yellow-anabled: #ffba33;
$color-primary-yellow-tapped: #e49700;
$color-primary-yellow-disabled: #ffe7b7;

$color-gray0-5: #fafafa;
$color-gray1: #f0f0f0;
$color-gray3: #999999;
$color-gray4: #4a4a4a;
