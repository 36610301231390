.strike {
	position: relative;
	white-space: nowrap;

	&::before {
		@include absolute($top: 50%, $right: -12px, $left: -8px);
		content: '';
		background: url("../images/strike.svg") center/100% 100% no-repeat;
		height: .48em;
		transform: translateY(-50%);
	}

	&::after {
		@include absolute($top: 0, $left: 50%);
		content: attr(data-correct);
		transform: translate(-50%, -80%) rotate(-3deg);
	}
}

.underline {
	position: relative;
	white-space: nowrap;

	&::before {
		@include absolute($top: 100%, $right: -18%, $left: -10%);
		content: '';
		background: url("../images/underline.svg") center/100% 100% no-repeat;
		height: .62em;
	}
}
